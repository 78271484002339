var render = function () {
  var _vm$tournament, _vm$tournament2, _vm$tournament3, _vm$tournament4, _vm$tournament5, _vm$matches, _vm$tournament6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "inherit"
    }
  }, [_c('match-info-modal', {
    attrs: {
      "match": _vm.selectedMatch
    }
  }), _c('b-card', {
    staticClass: "p-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "align-items-center justify-content-between"
  }, [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "m-0"
  }, [_vm._v(" Tournament matches ")])]), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search participant ...",
      "type": "search"
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "height": "20px",
      "icon": "SearchIcon",
      "width": "20px"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.matchesAllLoading ? _c('b-row', {
    staticClass: "text-center p-2"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _vm._e(), ((_vm$tournament = _vm.tournament) === null || _vm$tournament === void 0 ? void 0 : _vm$tournament.status_id) < _vm.TournamentStatusType.IN_PROGRESS ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-column align-items-center"
  }, [_c('feather-icon', {
    staticClass: "m-2",
    attrs: {
      "icon": "FrownIcon",
      "size": "50"
    }
  }), _vm._v(" Available when tournament is started ")], 1)], 1) : _vm._e(), !_vm.matchesAllLoading && _vm.showMatchReadyInfoCard && (((_vm$tournament2 = _vm.tournament) === null || _vm$tournament2 === void 0 ? void 0 : _vm$tournament2.status_id) !== _vm.TournamentStatusType.FINISHED || ((_vm$tournament3 = _vm.tournament) === null || _vm$tournament3 === void 0 ? void 0 : _vm$tournament3.status_id) !== _vm.TournamentStatusType.CLOSED) ? _c('b-row', {
    staticClass: "mb-1 flex-column"
  }, [_c('b-col', [_c('h5', [_vm._v("My matches:")])]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "p-2 h-100 m-0",
    staticStyle: {
      "min-height": "233px"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center flex-column flex-grow-1"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v(" When match is ready it will be displayed here. ")]), _c('div', {
    staticClass: "text-center mt-2"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BookmarkIcon",
      "size": "50"
    }
  })], 1)])])], 1), _vm._l(_vm.myReadyMatches, function (match) {
    return _c('b-col', {
      key: match.id,
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c('tournament-match-ready-card', {
      attrs: {
        "match": match
      },
      on: {
        "onMatchInfoClick": _vm.handleMatchInfoClick
      }
    })], 1);
  })], 2) : _vm._e(), _vm.matchesAllLoading === false && ((_vm$tournament4 = _vm.tournament) === null || _vm$tournament4 === void 0 ? void 0 : _vm$tournament4.status_id) >= _vm.TournamentStatusType.IN_PROGRESS ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-center justify-content-md-start"
  }, [_c('b-form-group', [_c('b-form-radio-group', {
    attrs: {
      "id": "btn-radios-1",
      "button-variant": "outline-primary",
      "options": _vm.filterOptions,
      "buttons": "",
      "name": "radios-btn-default"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('vue-perfect-scrollbar', {
    staticClass: "scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings
    }
  }, [_vm.matchesAllLoading === false && ((_vm$tournament5 = _vm.tournament) === null || _vm$tournament5 === void 0 ? void 0 : _vm$tournament5.status_id) >= _vm.TournamentStatusType.IN_PROGRESS ? _c('b-row', [_vm._l(_vm.matches, function (match) {
    return _c('b-col', {
      key: match.id,
      staticClass: "d-flex mb-1",
      attrs: {
        "md": "6",
        "sm": "12",
        "lg": "3"
      }
    }, [_c('tournament-match-card', {
      key: match.id,
      attrs: {
        "match": match
      },
      on: {
        "onMatchInfoClick": _vm.handleMatchInfoClick
      }
    })], 1);
  }), !_vm.matchesAllLoading && (!_vm.matches || ((_vm$matches = _vm.matches) === null || _vm$matches === void 0 ? void 0 : _vm$matches.length) === 0) ? _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center flex-column"
  }, [_c('div', {
    staticClass: "font-italic m-2 d-flex justify-content-center"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "InfoIcon",
      "size": "40"
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column align-items-center "
  }, [_c('p', [_vm._v("No matches found.")]), ((_vm$tournament6 = _vm.tournament) === null || _vm$tournament6 === void 0 ? void 0 : _vm$tournament6.status_id) === _vm.TournamentStatusType.PUBLISHED ? _c('p', {
    staticClass: "font-italic "
  }, [_vm._v(" Matches are going to appear once tournament starts ")]) : _vm._e()])])]) : _vm._e()], 2) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }